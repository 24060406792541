import React from 'react';

import { Jumbotron, Button } from 'react-bootstrap';
import styles from './home.module.css';
import cx from 'classnames';

const Home = () => {
    return (
        <div name="home" className={cx('container', styles.border)}>
            <Jumbotron className={styles.jumbotron}>
                <p><span className={styles.greenFont}>Hi, my name is</span></p>
                <h1 className={cx('display-3', styles.name)}>Krystian Stec</h1>
                <h1 className={cx('display-5', styles.desc)}>and I'm a front-end developer</h1>
                <p className={styles.desc}></p>
                <p>
                    <Button href="#contact" variant="outline-success">Drop a message</Button>
                </p>
            </Jumbotron>
        </div>
    )
};

export default Home;
