import React from 'react';
import { Home, About, NavbarMain, Projects, Contact, Banner } from './components';
//eslint-disable-next-line
import styles from './App.module.css';

const App = () => {
	return (
		<div>
			<NavbarMain />
			<Home />
			<About />
			<Projects />
			<Contact />
			<Banner />
		</div>
	);
};

export default App;
