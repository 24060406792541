import React from 'react';
import styles from '../banner/banner.module.css'
const Banner = () => {
  return (
    <div>
      <h1 className={styles.banner}>krystianstec0@gmail.com<span></span></h1>
    </div>
  )
};

export default Banner;