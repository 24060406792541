import React, { useEffect } from 'react';

import Aos from 'aos';
import Form from './form.styled';

import { Container } from 'react-bootstrap';
import styles from '../contact/contact.module.css';

const Contact = () => {
	useEffect(() => {
		Aos.init({ duration: 1000 });
	}, []);

	return (
		<div name="contact" id="contact" data-aos="flip-down" data-aos-offset='200' className={styles.border}>
			<Container className={styles.contact}>
				<div className={styles.jumbotron}>
					<h1 className={styles.main}>Drop A Message</h1>
					<p>
						Have any suggestions about my projects or maybe just want to recommend favourite series? Feel
						free to share it here!
					</p>
					<Form buttonText="Say Hi!" contentsPlaceholder="Tell me.." to="krystainstec0@gmail.com" />
				</div>
			</Container>
		</div>
	);
};

export default Contact;
