import React from 'react';
import styles from './navbarmain.module.css';
import { Navbar, NavDropdown, Nav, Button } from 'react-bootstrap';
import logo from '../../../src/logo.png';
import { Link } from 'react-scroll';
import resume from '../../../src/resume.pdf';

const NavbarMain = () => {
	let prevScrollpos = window.pageYOffset;

	window.onscroll = function() {
		let currentScrollPos = window.pageYOffset;

		console.log('scrolls');
		if (prevScrollpos > currentScrollPos) {
			document.getElementById('navbar').style.top = '0';
		} else {
			document.getElementById('navbar').style.top = '-80px';
		}
		prevScrollpos = currentScrollPos;
	};

	return (
		<Navbar id="navbar" collapseOnSelect expand="lg" bg="#0a192f" variant="dark" className={'fixed-top'}>
			<Link activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={500}>
				<Navbar.Brand href="/">
					<img src={logo} alt="logo" />
				</Navbar.Brand>
			</Link>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className={'mr-auto'}>
					<NavDropdown.Divider />
				</Nav>
				<Nav id="links">
					<Link activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={500}>
						<Nav.Link href="#about">
							<span className={styles.greenFont}>01 </span>About
						</Nav.Link>
					</Link>
					<Link activeClass="active" to="work" spy={true} smooth={true} offset={-70} duration={500}>
						<Nav.Link href="#work">
							<span className={styles.greenFont}>02 </span>Work
						</Nav.Link>
					</Link>
					<Link activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={500}>
						<Nav.Link href="#contact">
							<span className={styles.greenFont}>03 </span>Contact
						</Nav.Link>
					</Link>
					<Nav.Link href="https://github.com/pewientaki">
						<span className={styles.greenFont}>04 </span>Github
					</Nav.Link>
					<Link activeClass="active" to="#contact" spy={true} smooth={true} offset={-70} duration={500} />
					<a href={resume}>
						<Button className="button" variant="outline-success">
							Resume
						</Button>
					</a>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavbarMain;
