import React, {useEffect} from 'react';

import {Container, Row, Col} from 'react-bootstrap';
import styles from './projects.module.css';
import cx from 'classnames';

import Aos from 'aos';
import 'aos/dist/aos.css';

import * as projectsData from '../../assets/projects';

/**
 * Returns created project description section.
 *
 * @param {Number} index Project's index.
 * @param {Object<*>} project Project's data.
 * @return {JSX.Element} Returns newly created project description section.
 */
function createProjectSection(index, project) {
    const isRight = index % 2 === 1;

    return (
        <Col xs={{order: index + 1}} lg={{order: index}}>
            <p className={styles.sample}>Sample project</p>
            <h3>
                <b>{project.name}</b>
            </h3>
            <p className={styles.desc}>{project.desc}</p>
            <div className={styles.container}>
                <ul className={cx(styles.ul, styles[isRight ? 'ulright' : 'ulleft'])}>
                    {project.utils.map((util) =>
                        <li className={styles.list}>{util}</li>
                    )}
                </ul>
            </div>
            <div>
                <a href={project.github} className={styles.icons}>
                    <i className="fab fa-github"/>
                </a>
                <a href={project.link} className={styles.icons}>
                    <i className="fas fa-external-link-alt"/>
                </a>
            </div>
        </Col>
    );
}

/**
 * Returns created image section.
 *
 * @param {Number} index Project's index.
 * @param {Object<*>} project Project's data.
 * @return {JSX.Element} Returns newly created image display section.
 */
function createImageSection(index, project) {
    return (
        <Col xs={{order: index}} lg={{order: index}}>
            <div>
                <a href={project.link}>
                    <img
                        src={project.image}
                        className={cx('image-fluid', styles.image)}
                        alt={project.name}
                    />
                </a>
            </div>
        </Col>
    );
}

/**
 * Returns a complete project section.
 *
 * @param {Number} index Project's index.
 * @param {Object<*>} project Project's data.
 * @return {JSX.Element} Returns newly section for a given project.
 */
function createProject(index, project) {
    const isRight = index % 2 === 1;

    if (isRight) {
        return [
            createProjectSection(index, project),
            createImageSection(index, project)
        ]
    } else {
        return [
            createImageSection(index, project),
            createProjectSection(index, project)
        ]
    }
}

const Projects = () => {
    useEffect(() => {
        Aos.init({'duration': 1500, 'once': true, 'disable': false, 'easing': 'ease-in'});
    }, []);

    return (
        <div className={styles.projects} name="work" id="work">
            <Container>
                <h3>
                    <span className={styles.sectionNumber}>02 </span>Work
                </h3>

                {projectsData.projects.map((project, index) => {
                    return (
                        <div {...project.animation} className={styles.projectsDisplay}>
                            <Row>
                                {createProject(index, project)}
                            </Row>
                        </div>
                    )
                })}
            </Container>
        </div>
    );
};

export default Projects;
