import React, { useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import styles from './about.module.css';
import cx from 'classnames';

import profileImage from '../../assets/images/profile.jpg';

import Aos from 'aos';

const About = () => {
	useEffect(() => {
		Aos.init({ duration: 1000 });
	}, []);

	return (
		<div name="about" data-aos="fade-up" data-aos-offset='300' className={styles.about}>
			<Container>
				<Row>
					<h3>
						<span className={styles.sectionNumber}>01 </span>About Me
					</h3>
				</Row>
				<Row>
					<Col md={6}>
						<p>
							Hey! I'm Krystian, a 28-year-old who is into Front-end development, UI Design and new
							technologies. I'm based in London, UK.
						</p>
						<p>
							I'm currently working as a junior JavaScript developer creating games for one of the largest betting companies. Even though
							I'm not looking for a new position I'm happy to hear about any feedback or suggestions
							regarding my website or other projects!
						</p>
					</Col>
					<Col md={6}>
						<img className={cx(styles.image, 'image-fluid')} src={profileImage} alt="profile" />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default About;
