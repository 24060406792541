import * as soundsGame from './images/pattaptap.png';
import * as dogsHotel from './images/dogshotel.png';
import * as pokemonGame from './images/pokemonGame.png';

export const projects = [

    {
        'name': 'Pokemon Game',
        'desc': 'Vanilla JavaScript game, pika-pika!',
        'utils': ['JavaScript, HTML5, CSS3'],
        'image': pokemonGame,
        'link': 'https://pewientaki.github.io/catch-coin-game/',
        'github': 'https://github.com/pewientaki/catch-coin-game/',
        'animation': {'data-aos': 'fade-up', 'data-aos-offset': 200}
    },
    {
        'name': 'Happy Paws Hotels',
        'desc': 'Website with variety of dog hotels, choose one or add your own!',
        'utils': ['JavaScript', 'BootStrap', 'Mongoose', 'MongoDB', 'Express', 'Passport'],
        'image': dogsHotel,
        'link': 'https://tranquil-ocean-48716.herokuapp.com/',
        'github': 'https://github.com/pewientaki/doghotels/',
        'animation': {'data-aos': 'fade-up-right', 'data-aos-offset': 200}

    },
    {
        'name': 'Sounds Game',
        'desc': 'A website made of some JavaScrips libraries, each key press plays a sound and displays an object!',
        'utils': ['JavaScript', 'Paper-full', 'Howler JS'],
        'image': soundsGame,
        'link': 'https://pewientaki.github.io/SoundsGame/patTapApp.html',
        'github': 'https://github.com/pewientaki/SoundsGame',
        'animation': {'data-aos': 'fade-up-left', 'data-aos-offset': 200}
    },
];
